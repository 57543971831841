<template>
  <div
    class="lopa px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full">
        <TitleComponent title="Layer of Protection Analysis" size="large" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusio
            n-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/lopa_worksheet.png"
              width="611"
              height="786"
              alt=""
              title="LOPA_Worksheet"
              class="img-responsive wp-image-11857"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <Paragraph>
            <p>
              Layer of Protection Analysis (LOPA) is a semi-quantitative risk
              assessment method that provides the middle ground between full
              quantitative risk assessments (QRA) and qualitative process hazard
              analyses (PHA). LOPA can be performed during or after a PHA to
              further evaluate the risk of each identified failure mode or
              deviation.
            </p>
            <p>
              Safety Management Services, Inc. (SMS) employs the Center for
              Chemical Process Safety (CCPS) methodology for conducting LOPA.
              The process begins by determining a tolerable frequency in
              failures/year for each level of severity as outlined in the risk
              matrix used for the PHA. SMS prefers the MIL-STD-882E hazard risk
              assessment matrix.
            </p>
            <p>
              For each scenario selected for LOPA, the initiating event and its
              associated initiating event frequency (IEF) are identified. These
              initiating events often stem from human error or failures in the
              basic process control system (BPCS), although other events may
              also be considered.
            </p>
            <p>
              Next, any modifiers to the IEF are accounted for. These can
              include enabling conditions (EC), such as probability of seasonal
              risks or specific states of an operation, and conditional
              modifiers (CM), such as probability of initiation or probability
              of personnel presence.
            </p>
            <p>
              Lastly, any safeguards identified during the PHA are evaluated to
              determine if they qualify as independent protection layers (IPL).
              To be considered IPLs, safeguards must demonstrate independence,
              functionality, reliability, integrity, auditability, access
              security, and management of change. For calculations, IPLs are
              expressed as a probability of failure on demand
              (IPL<sub>PFD</sub>).
            </p>
            <p>
              The overall mitigated event frequency (MEF) can be calculated by
              the following equation:
            </p>
            <p>
              <center>
                <strong>MEF = IEF × EC × CM × IPL<sub>PFD</sub></strong>
              </center>
            </p>
            <p>
              If the MEF does not meet the tolerable frequency, recommendations
              are issued for additional IPLs or for increasing the safety
              integrity level (SIL) of already existing safety instrumented
              functions (SIF).
            </p>
          </Paragraph>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Hazard and Operability analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "A Hazard and Operability (HAZOP) analysis is a systematic study performed by a multi-disciplinary team to assess how deviations from design intent can occur as well as the consequences. HAZOP analysis uses a series of guide words to identify hazards and recommendations during all operation phases."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
